<template>
    <div class="main-container">
        <div class="banner-container">
            <img src="../assets/images/pods.jpg" alt="">
            <el-row class="show-img">
                <el-col :span="24">
                    <div class="bgi">
                        <div class="text-content">
<!--                            <p class="header">麦客PODS 一 生产流程管理解决方案</p>-->
                            <p>杭州麦客网科技有限公司投资3000万，</p>
                            <p>走访数十家传统商务印刷、标签加工、包装加工企业，</p>
                            <p>历经4年开发规模定制化行业打造的管理系统。</p>
                            <p>2015年投入商用，为全国各区域印刷加工生产中心采用</p>
                        </div>
                        <el-row>
                            <el-col :span="24">
<!--                                <router-link to="/console">-->
<!--                                    <el-button style="width: 260px;font-size: 24px;height: 66px;" type="primary">免费试用</el-button>-->
<!--                                </router-link>-->
                                <div style="margin-top: 12px;margin-bottom: 68px;">
                                    <a href="javascript:" @click="goAnchor('#price')">查看价格</a>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="common-container">
            <div style="padding-top: 52px">
                <template>
                    <el-table
                            :data="tableData"
                            border
                            style="width: 100%">
                        <el-table-column
                                prop="name"
                                label="功能模块"
                                width="180">
                        </el-table-column>
                        <el-table-column
                                prop="description"
                                label="说明">
                        </el-table-column>
                        <el-table-column
                                label="PODS go"
                                align="center"
                                width="200">
                            <template slot-scope="scope">
                                <i v-if="scope.row.go" class="el-icon-check"></i>
                                <i v-else class="el-icon-close"></i>
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="PODS pro"
                                align="center"
                                width="200">
                            <template slot-scope="scope">
                                <i v-if="scope.row.pro" class="el-icon-check"></i>
                                <i v-else class="el-icon-close"></i>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
            </div>
            <div style="padding-top: 24px">
                <h2 id="price">系统价格 (更多详细信息请咨询: 400-998-0808)</h2>
                <template>
                    <el-table
                            :data="newPriceData"
                            border
                            style="width: 100%">
                        <el-table-column
                                prop="name"
                                label="类型"
                                width="240">
                        </el-table-column>
                        <el-table-column
                            prop="price"
                            label="费用">
                        </el-table-column>
                    </el-table>
                </template>
<!--                <template>-->
<!--                    <el-table-->
<!--                            :data="priceData"-->
<!--                            border-->
<!--                            style="width: 100%">-->
<!--                        <el-table-column-->
<!--                                prop="name"-->
<!--                                label="收费项目"-->
<!--                                width="240">-->
<!--                        </el-table-column>-->
<!--                        <el-table-column-->
<!--                                label="说明">-->
<!--                            <template slot-scope="scope">-->
<!--                                <div v-html="scope.row.description"></div>-->
<!--                            </template>-->
<!--                        </el-table-column>-->
<!--                        <el-table-column-->
<!--                                prop="type"-->
<!--                                label="服务类型"-->
<!--                                width="100">-->
<!--                        </el-table-column>-->
<!--                        <el-table-column-->
<!--                                align="center"-->
<!--                                label="价格（元）">-->
<!--                            <el-table-column-->
<!--                                    prop="go"-->
<!--                                    align="right"-->
<!--                                    label="PODS go"-->
<!--                                    width="160">-->
<!--                            </el-table-column>-->
<!--                            <el-table-column-->
<!--                                    prop="pro"-->
<!--                                    align="right"-->
<!--                                    label="PODS pro"-->
<!--                                    width="160">-->
<!--                            </el-table-column>-->
<!--                        </el-table-column>-->
<!--                        <el-table-column-->
<!--                                prop="unit"-->
<!--                                label="收费单位"-->
<!--                                width="100">-->
<!--                        </el-table-column>-->
<!--                    </el-table>-->
<!--                </template>-->
            </div>
<!--            <div style="padding-top: 24px;">-->
<!--                <h2>SKU套餐包</h2>-->
<!--                <template>-->
<!--                    <el-table-->
<!--                            :data="packageData"-->
<!--                            border-->
<!--                            style="width: 100%">-->
<!--                        <el-table-column-->
<!--                                prop="name"-->
<!--                                label="套餐包"-->
<!--                                width="180">-->
<!--                        </el-table-column>-->
<!--                        <el-table-column-->
<!--                                prop="quantity"-->
<!--                                label="可用SKU数量"-->
<!--                                align="right"-->
<!--                                width="180">-->
<!--                        </el-table-column>-->
<!--                        <el-table-column-->
<!--                                prop="price"-->
<!--                                label="价格（元）"-->
<!--                                align="right"-->
<!--                                width="180">-->
<!--                        </el-table-column>-->
<!--                        <el-table-column-->
<!--                                prop="description"-->
<!--                                label="说明">-->
<!--                        </el-table-column>-->
<!--                    </el-table>-->
<!--                </template>-->
<!--            </div>-->
            <div style="padding-top: 24px">
                <h2>系统实施及服务明细</h2>
                <template>
                    <el-table
                            :data="ServiceDetail"
                            border
                            style="width: 100%">
                        <el-table-column
                                prop="name"
                                label="服务项目"
                                width="280">
                        </el-table-column>
                        <el-table-column
                                label="说明">
                            <template slot-scope="scope">
                                <div v-html="scope.row.description"></div>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="type"
                                label="服务类型"
                                width="100">
                        </el-table-column>
                        <el-table-column
                                label="PODS go"
                                align="center"
                                width="180">
                            <template slot-scope="scope">
                                <i v-if="scope.row.go" class="el-icon-check"></i>
                                <i v-else class="el-icon-close"></i>
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="PODS pro"
                                align="center"
                                width="180">
                            <template slot-scope="scope">
                                <i v-if="scope.row.pro" class="el-icon-check"></i>
                                <i v-else class="el-icon-close"></i>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
            </div>
<!--            <div style="margin: 50px 0;width: 100%;text-align: center">-->
<!--                <router-link to="/console">-->
<!--                    <el-button style="width: 260px;font-size: 24px;height: 66px;" type="primary">免费试用</el-button>-->
<!--                </router-link>-->
<!--            </div>-->
            <div>
                <h2 id="requirement">PODS go 硬件要求</h2>
                <template>
                    <el-table
                            :data="PODSGORequirement"
                            border
                            style="width: 100%">
                        <el-table-column
                                prop="name"
                                label="硬件名称"
                                width="180">
                        </el-table-column>
                        <el-table-column
                                prop="description"
                                label="说明"
                                width="">
                        </el-table-column>
                        <el-table-column
                                prop="requirement"
                                label="硬件要求"
                                width="300">
                        </el-table-column>
                        <el-table-column
                                prop="quantity"
                                label="数量"
                                width="180">
                        </el-table-column>
                    </el-table>
                </template>
            </div>
            <div style="padding-bottom: 60px">
                <h2>PODS pro 硬件要求</h2>
                <template>
                    <el-table
                            :data="PODSPRORequirement"
                            border
                            style="width: 100%">
                        <el-table-column
                                prop="name"
                                label="硬件名称"
                                width="220">
                        </el-table-column>
                        <el-table-column
                                prop="description"
                                label="说明"
                                width="">
                        </el-table-column>
                        <el-table-column
                                prop="requirement"
                                label="硬件要求"
                                width="300">
                        </el-table-column>
                        <el-table-column
                                prop="quantity"
                                label="数量"
                                width="180">
                        </el-table-column>
                    </el-table>
                </template>
            </div>
        </div>
<!--        <div class="common-container">-->
<!--            <el-row class="introduce first">-->
<!--                <el-col :span="11">-->
<!--                    <p class="text-header">自建全产品线接单网站-客户自助询价下单</p>-->
<!--                    <div class="text-introduce">-->
<!--                        <ul>-->
<!--                            <li>产品报价</li>-->
<!--                            <li>快捷询价</li>-->
<!--                            <li>订单管理：支持线上付款、标准接口服务</li>-->
<!--                            <li>账户中心</li>-->
<!--                            <li>消息通知：与客户远程近距离</li>-->
<!--                            <li>小程序：通知、报价、下单、查单(2019年10月上线)</li>-->
<!--                        </ul>-->
<!--                    </div>-->
<!--                </el-col>-->
<!--                <el-col :span="13">-->
<!--                    <img height="300" src="../assets/images/pods1.jpg" alt="">-->
<!--                </el-col>-->
<!--            </el-row>-->
<!--            <el-row class="introduce">-->
<!--                <el-col :span="13">-->
<!--                    <img height="300" src="../assets/images/pods2.jpg" alt="">-->
<!--                </el-col>-->
<!--                <el-col :span="11">-->
<!--                    <p class="text-header">全定制产品智能报价、自动报工</p>-->
<!--                    <div class="text-introduce">-->
<!--                        <ul>-->
<!--                            <li>适应数码、传统、平张、卷筒产品</li>-->
<!--                            <li>完全模拟人工报价、以产线工艺精确报工(2019年10月上线)</li>-->
<!--                            <li>各种标准化、非标准化产品均可自由配置</li>-->
<!--                            <li>实现每订单实时成本核算</li>-->
<!--                        </ul>-->
<!--                    </div>-->
<!--                </el-col>-->
<!--            </el-row>-->
<!--            <el-row class="introduce">-->
<!--                <el-col :span="11">-->
<!--                    <p class="text-header">批量集约，全业务线任务管理</p>-->
<!--                    <div class="text-introduce">-->
<!--                        <ul>-->
<!--                            <li>生产规模化</li>-->
<!--                            <li>数据透明化</li>-->
<!--                            <li>提高生产效率</li>-->
<!--                            <li>任务分配自动化，减少重复工作</li>-->
<!--                            <li>严谨的流程管理，降低差错率</li>-->
<!--                        </ul>-->
<!--                    </div>-->
<!--                </el-col>-->
<!--                <el-col :span="13">-->
<!--                    <img height="300" src="../assets/images/pods3.jpg" alt="">-->
<!--                </el-col>-->
<!--            </el-row>-->
<!--            <el-row class="introduce" style="margin-bottom: 200px">-->
<!--                <el-col :span="13">-->
<!--                    <img height="300" src="../assets/images/pods4.jpg" alt="">-->
<!--                </el-col>-->
<!--                <el-col :span="11">-->
<!--                    <p class="text-header">开发透明的生产流程控制</p>-->
<!--                    <div class="text-introduce">-->
<!--                        <ul>-->
<!--                            <li>工厂调度</li>-->
<!--                            <li>车间调度</li>-->
<!--                            <li>工单调度</li>-->
<!--                            <li>人员设备数据可视化</li>-->
<!--                        </ul>-->
<!--                    </div>-->
<!--                </el-col>-->
<!--            </el-row>-->
<!--        </div>-->
    </div>
</template>

<script>

    export default {
        props: {},
        data() {

            return {
                tableData: [
                    {
                        name: '客户前端',
                        description: '客户账户管理、账号管理、报价记录、购物车、订单记录、成本中心、发票管理、充值、线上/线下支付',
                        go: true,
                        pro: true,
                    },
                    {
                        name: '客户前端小程序',
                        description: '账户管理、订单查询、消息通知、手机报价（v3.1）、手机下单（v3.1）',
                        go: true,
                        pro: true,
                    },
                    {
                        name: '权限角色',
                        description: '管理员账号、角色、权限配置',
                        go: true,
                        pro: true,
                    },
                    {
                        name: '任务管理',
                        description: '客服人员排班、任务流程、权限配置',
                        go: true,
                        pro: true,
                    },
                    {
                        name: '产品管理',
                        description: '产品模版配置、上线管理、线上报价',
                        go: true,
                        pro: true,
                    },
                    {
                        name: '订单管理',
                        description: '订单审核、售后处理',
                        go: true,
                        pro: true,
                    },
                    {
                        name: '报价管理',
                        description: '线上询价、专业报价',
                        go: true,
                        pro: true,
                    },
                    {
                        name: '客户管理',
                        description: '账号管理、子账号管理',
                        go: true,
                        pro: true,
                    },
                    {
                        name: '商务管理',
                        description: '结算方式、会员合同、协议合同',
                        go: true,
                        pro: true,
                    },
                    {
                        name: '生产调度',
                        description: '发单、生产地调度',
                        go: true,
                        pro: true,
                    },
                    {
                        name: '工厂管理',
                        description: '车间管理、工作站管理、设备管理、工作站排班管理',
                        go: false,
                        pro: true,
                    },
                    {
                        name: '印前管理',
                        description: '自动报工、自动拼版（v3.1）、手动报工、手动拼版',
                        go: false,
                        pro: true,
                    },
                    {
                        name: '工单管理',
                        description: '制版工单、领料单、生产工单、工作站管理、扫码/PDA工单跟踪、自动批量、产能统计、效率统计',
                        go: false,
                        pro: true,
                    },
                    {
                        name: '生产后台小程序',
                        description: '工单管理、工单执行、统计展示、消息通知',
                        go: false,
                        pro: true,
                    },
                    {
                        name: '分拣配送',
                        description: '分拣、第三方物流对接、自建送货管理、车辆管理',
                        go: true,
                        pro: true,
                    },
                    {
                        name: '供应链管理（v3.1）',
                        description: '供应商管理、库存管理、采购管理、发单管理',
                        go: true,
                        pro: true,
                    },
                    {
                        name: '账务管理-营收',
                        description: '收款、应收对账、审批',
                        go: true,
                        pro: true,
                    },
                    {
                        name: '账务管理-成本',
                        description: '付款、应付对账、审批',
                        go: true,
                        pro: true,
                    },
                    {
                        name: '展示管理',
                        description: '官网展示样式、图片管理、线上推广链接生成',
                        go: true,
                        pro: true,
                    },
                ],
                priceData: [
                    {
                        name: '系统开通',
                        description: '超级管理员账号开通',
                        type: '-',
                        go: '10,000',
                        pro: '15,000',
                        unit: '每账号',
                    },
                    {
                        name: '系统维护',
                        description: '系统服务费用',
                        type: '-',
                        go: '2,400',
                        pro: '3,600',
                        unit: '每年',
                    },
                    {
                        name: '其它线上支付开通对接',
                        description: '线上支付系统对接（支付宝、微信支付无需支付额外服务费）',
                        type: '远程',
                        go: '2,000',
                        pro: '2,000',
                        unit: '每收款账号',
                    },
                    {
                        name: '第三方配送对接',
                        description: '第三方物流面单打印安装调测及电子面单系统对接',
                        type: '远程',
                        go: '2,000',
                        pro: '2,000',
                        unit: '每配送账号',
                    },
                    {
                        name: '系统初始化及实施',
                        description: '系统初始化及实施费用',
                        type: '-',
                        go: '34,000',
                        pro: '44,000',
                        unit: '一次性费用',
                    },
                ],
                newPriceData: [
                    {
                        name: '公有云 (麦客网云服务)',
                        price: '150,000 元'
                    },
                    {
                        name: '私有云 (独立部署)',
                        price: '600,000 元'
                    },
                ],
                packageData: [
                    {
                        name: '开门红',
                        quantity: '20,000',
                        price: '3,000',
                        description: '0.15元每SKU',
                    },
                    {
                        name: '节节高',
                        quantity: '60,000',
                        price: '6,000',
                        description: '0.10元每SKU',
                    },
                    {
                        name: '大满贯',
                        quantity: '200,000',
                        price: '16,000',
                        description: '0.08元每SKU',
                    }
                ],
                ServiceDetail: [
                    {
                        name: '权限角色、任务模块配置',
                        description: '账号、角色、权限配置<br>任务数据配置，流程配置',
                        type: '远程',
                        go: true,
                        pro: true,
                    },
                    {
                        name: '产品基础数据配置',
                        description: '设备参数配置<br>工序数据初始化，价格录入<br>材料数据初始化，价格录入<br>产品模版初始化设置、产品数据导入',
                        type: '远程',
                        go: true,
                        pro: true,
                    },
                    {
                        name: '商务合同配置',
                        description: '客户、商务数据录入，商务参数设置',
                        type: '远程',
                        go: true,
                        pro: true,
                    },
                    {
                        name: '展示模块配置',
                        description: 'WEBS主页展示，产品介绍、展示',
                        type: '远程',
                        go: true,
                        pro: true,
                    },
                    {
                        name: '分拣配送配置',
                        description: '分拣架配置，分拣规则实施',
                        type: '远程',
                        go: true,
                        pro: true,
                    },
                    {
                        name: '工厂配置',
                        description: '工厂、车间、工作站数据初始化',
                        type: '远程',
                        go: false,
                        pro: true,
                    },
                    {
                        name: '供应链模块初始化',
                        description: '任务管理流程初始化及配置',
                        type: '远程',
                        go: true,
                        pro: true,
                    },
                    {
                        name: '支付宝、微信线上支付申请及对接',
                        description: '支付宝、微信支付代申请，系统对接',
                        type: '远程',
                        go: true,
                        pro: true,
                    },
                    {
                        name: '设备Hot folder/JDF/JMF调试',
                        description: '各设备共享文件夹、热文件夹、JDF、JMF配置及调测',
                        type: '远程',
                        go: false,
                        pro: true,
                    },
                    {
                        name: '文件服务器安装调试',
                        description: '文件管理，自动拼版、自动预检处理程序安装，网络调试',
                        type: '远程',
                        go: false,
                        pro: true,
                    },
                    {
                        name: '扫码打印设备安装',
                        description: '生产调度、工作站、装配、分拣条码识别软件安装调测',
                        type: '远程',
                        go: true,
                        pro: true,
                    },
                    {
                        name: '操作培训',
                        description: 'PODS系统配置、使用培训，人数不限',
                        type: '远程',
                        go: true,
                        pro: true,
                    },
                ],
                PODSGORequirement: [
                    {
                        name: '生产单打印机',
                        description: '用于纸质生产单、如采用网络发送可不配',
                        requirement: '主流A4黑白打印机',
                        quantity: '1台',
                    },
                    {
                        name: '台式电脑',
                        description: '用于分拣任务',
                        requirement: '主流PC',
                        quantity: '1台',
                    },
                    {
                        name: '工业用扫码枪',
                        description: '用于分拣',
                        requirement: '无线/有线串行扫码枪，CODE128',
                        quantity: '1台',
                    },
                    {
                        name: '不干胶标签打印机',
                        description: '用于装配工作站打印产品分拣码、以及装箱唛头打印及快递面单打印（快递选配）',
                        requirement: '热敏、200DPI',
                        quantity: '2台',
                    },
                    {
                        name: '送货清单打印机',
                        description: '用于分拣完成打印送货清单',
                        requirement: '热敏4寸，200DPI',
                        quantity: '1台',
                    },
                ],
                PODSPRORequirement: [
                    {
                        name: '文件服务器',
                        description: '用于本地源文件、拼版文件、工艺文件存储，自动拼版、自动预检处理程序运行',
                        requirement: '主流CPU服务器，内存16G，磁盘阵列 或者 服务器 硬盘 >=4T',
                        quantity: '1台',
                    },
                    {
                        name: '生产单/工单打印机',
                        description: '用于纸质生产单、工单打印，如采用电子工单可不配。用于装箱单、装车单打印',
                        requirement: '主流A4黑白打印机',
                        quantity: '1台',
                    },
                    {
                        name: '台式电脑/工业终端/手持终端',
                        description: '用于每个工作站接收工单任务，输入执行结果',
                        requirement: '主流PC/工业用计算机/PDA —支持浏览器',
                        quantity: '每工作站1台',
                    },
                    {
                        name: '工业用扫码枪',
                        description: '用于每个工作站扫描纸质工单',
                        requirement: '无线/有线串行扫码枪，CODE128',
                        quantity: '每工作站1台',
                    },
                    {
                        name: '不干胶标签打印机',
                        description: '用于装配工作站打印产品分拣码、以及装箱唛头打印及快递面单打印（快递选配）',
                        requirement: '热敏、200DPI8',
                        quantity: '2台',
                    },
                    {
                        name: '送货清单打印机',
                        description: '用于分拣完成打印送货清单',
                        requirement: '热敏4寸，200DPI',
                        quantity: '1台',
                    },
                ]
            }
        },
        created() {},
        mounted() {
            if (location.hash) {
                this.$nextTick(() => {
                    this.goAnchor(location.hash)
                })
                // console.log(location.hash)
            }
        },
        watch: {},
        computed: {},
        methods: {
            goAnchor(selector) {
                var anchor = this.$el.querySelector(selector) // 参数为要跳转到的元素id
                document.body.scrollTop = anchor.offsetTop // chrome
                document.documentElement.scrollTop = anchor.offsetTop // firefox
            },
            handleClick() {
                // console.log(tab, event);
            }
        },
        components: {},
    }
</script>

<style lang="scss" scoped>
    .el-table .cell {
        white-space: pre-line;
    }
    .el-icon-check {
        font-size: 16px;
        font-weight: bold;
        color: #34A853;
    }
    .el-icon-close {
        font-size: 16px;
        font-weight: bold;
        /*color: #aaa;*/
    }
    .show-img {
        margin-top: 2px;
        .first.el-col {
            display: grid;
        }
        img {
            position: relative;
            display: block;
            width: 1920px;
            left: 50%;
            margin-left: -960px;
        }
        .bgi {
            position: relative;
            display: block;
            width: 1920px;
            left: 50%;
            margin-left: -960px;
            background-image: url('../assets/images/podsbj.jpg');
            .text-content {
                margin-top: 90px;
                text-align: center;
            }
            .el-row {
                text-align: center;
                button.el-button {
                    font-size: 20px;
                    /*margin-bottom: 80px;*/
                }
            }
            p {
                font-size: 16px;
                margin-block-end: 0;
                margin-block-start: 0;
            }
            /*p:first-child {*/
            /*    font-size: 24px;*/
            /*    margin-bottom: 50px;*/
            /*}*/
            /*p:not(:first-child) {*/
            /*    line-height: 28px;*/
            /*}*/
            p {
                line-height: 28px;
            }
            p:last-child {
                margin-bottom: 50px;
            }
        }
    }
    .common-container {
        .text-header {
            color: #27364e;
            font-size: 24px;
        }
        .introduce {
            margin-top: 200px;
            .text-introduce {
                ul {
                    padding-inline-start: 18px;
                    li {
                        font-size: 16px;
                        line-height: 36px;
                    }
                }
            }
        }
        .introduce.first {
            margin-top: 80px;
        }
        .el-table th {
            background-color: #F5F7FA;
        }
    }
</style>
