<template>
    <div class="main-container">
        <div class="banner-container">
            <img src="../assets/images/home1.jpg" alt="">
        </div>
        <div class="home">
            <el-row class="category">
                <el-col :span="6" v-for="(category, index) in categoryList" :key="'index'+index">
                    <el-card>
                        <img width="73" height="73" :src="category.src" alt="">
                        <div class="card-bottom">
                            <span class="title">{{category.title}}</span>
                            <div class="bottom clearfix">
                                <div v-for="(explain, idx) in category.explainList" :key="'idx'+idx">
                                    <div class="dib vat ck">
                                        <p>
                                            <img width="16" height="16" src="../assets/images/ck.png" alt="">
                                        </p>
                                    </div>
                                    <div class="dib pl-8 w-24">
                                        <p>{{explain}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
            <el-row class="popularize">
                <el-col :span="24">
                    <div style="width: 1280px;margin: 0 auto;">
                        <div style="display: inline-block; vertical-align: top;font-size: 28px;text-align: left;font-weight: 600;line-height: 44px;margin-top: 140px;float: left;">
                            <div>
                                <span>为何选择</span>
                                <span style="color: #008ac8;">&nbsp;&nbsp;MaikeOS</span>
                            </div>
                            <div>我们的合作伙伴会告诉您答案</div>
                            <div style="display: inline-block;font-size: 14px;font-weight: normal;margin-top: 30px;">
                                <a href="javascript: void(0);">查看所有客户案例</a>
                            </div>
                        </div>
                        <div style="display: inline-block;float: right;">
                            <img src="../assets/images/partner.png" alt="">
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>

    export default {
        name: 'home',
        props: {},
        data () {

            let categoryList = [
                {
                    src: require('../assets/images/tu1.png'),
                    title: '快速搭建属于自己的流量平台',
                    explainList: [
                        '网络报价、接单、查单-微信同步通知订单状态、查账单、查帐户',
                        '小程序报价接单查单、充值',
                        '100%模拟线下交易，无需改变客户下单习惯',
                        '大文件云端/本地快速传输，文件备份查找便捷（2020年1月上线文件管理）'
                    ]
                },
                {
                    src: require('../assets/images/tu2.png'),
                    title: '印前自动化',
                    explainList: [
                        '解决印前报工的繁琐填单，数据智能预填',
                        '文件自动预检（出血、尺寸、转曲、色彩模式）',
                        '自动拼版（2020年12月上线）'
                    ]
                },
                {
                    src: require('../assets/images/tu3.png'),
                    title: '工业4.0标准的生产自动流程',
                    explainList: [
                        '全程支持扫码枪/手机扫码',
                        '一键返工补单',
                        '工单自动分配',
                    ]
                },
                {
                    src: require('../assets/images/tu4.png'),
                    title: '电商级分拣配送模块',
                    explainList: [
                        '可视化与自动校验结合的快速分拣方案',
                        '支持第三方物流的API（可实现自动运单追踪）',
                        '支持自建物流配送，装箱、装车、配送签收全程透明',
                    ]
                },
            ];

            return {
                categoryList,
            }
        },
        methods: {

        },
        components: {

        }
    }
</script>

<style lang="scss">
    .home {
        /*overflow: hidden;*/
        margin-top: -195px;

        .el-carousel__indicators--horizontal {
            display: none;
        }
        .el-carousel, .el-image {
            width: 100%;
        }
        .el-carousel__item {
            background-color: #99a9bf;
        }
        .category {
            width: 100%;
            margin: 100px auto 60px;
            .el-card {
                border: unset;
                .el-card__body {
                    text-align: center;
                    .card-bottom {
                        padding: 14px 24px;
                        .title {
                            font-size: 16px;
                            font-weight: 600;
                        }
                        .bottom {
                            margin-top: 8px;
                            .dib {
                                display: inline-block;
                            }
                            .vat {
                                vertical-align: top;
                            }
                            span {
                                line-height: 24px;
                            }
                            .pl-8 {
                                padding-left: 8px;
                            }
                            .ck {
                                width: 16px;
                                margin-top: 4px;
                                p {
                                    margin: 8px 0;
                                    line-height: 24px;
                                }
                            }
                            .w-24 {
                                text-align: left;
                                width: calc(100% - 24px);
                                p {
                                    margin: 8px 0;
                                    line-height: 24px;
                                }
                            }
                        }
                    }
                }
            }
            .el-card.is-always-shadow {
                box-shadow: unset;
            }
        }
        .popularize {
            width: 100%;
            height: 450px;
            text-align: center;
            background-color: #ceedf8;
            img {
                height: 450px;
            }
        }
    }
</style>
