<template>
    <div>
        <div class="main-container">
            <div class="banner-container">
                <img src="../assets/images/banner_down.jpg" alt="">
            </div>
        </div>
        <div style="width: 100%;padding: 12px 0;background: #f2f2f2">
            <div class="common-container" style="text-align: center;font-size: 1.4rem;">
                <i class="el-icon-service"></i>
                <span style="margin-left: 12px">
                    <a href="tel:4009980808">400-998-0808</a>
                </span>
            </div>
        </div>
        <div class="common-container">
            <div class="page-title">
                <span class="page-title">下载</span>
                <div class="border-bot"></div>
            </div>
            <div class="app">
                <h1>PODS</h1>
                <template>
                    <el-table
                            :data="tableData1"
                            border
                            style="width: 100%">
                        <el-table-column
                                prop="name"
                                label="附件及手册">
                        </el-table-column>
                        <el-table-column
                                label="下载">
                            <template slot-scope="scope">
                                <p :key="index" v-for="(item, index) in scope.row.attach">
                                    <a class="oxo-link primary" :href="item.url">
                                        {{ item.name }}
                                    </a>
                                </p>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Downloads",
        data () {
            return {
                tableData1: [
                    {
                        name: '附件',
                        attach: [
                            {
                                url: 'javascript:',
                                name: '扫码程序',
                            },
                            {
                                url: 'javascript:',
                                name: '打印机驱动',
                            }
                        ],
                    },
                    {
                        name: '手册',
                        attach: [
                            {
                                url: 'javascript:',
                                name: '产品手册',
                            },
                        ],
                    },
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>
    .app {
        margin-bottom: 52px;
    }
    .page-title {
        position: relative;
        text-align: center;
        margin: 28px auto;
        padding: 8px 0;
        span {
            font-size: 1.8rem;
            padding: 4px 0;
        }
        .border-bot {
            position: absolute;
            bottom: 0;
            width: 32px;
            left: 50%;
            margin-left: -16px;
            height: 4px;
            background: #1389FD;
            border-radius: 2px;
        }
    }
</style>