<template>
    <div class="common-container">
        <div class="auth-form">
            <el-tabs v-model="signInMethod" @tab-click="tabClick">
                <el-tab-pane :label="$t('Auth.Form.Tab1')" name="UsernameAndPassword">
                    <el-form label-position="top" :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px">
                        <el-form-item :label="$t('Auth.Form.Username')" prop="username">
                            <el-input type="text" v-model="ruleForm.username" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('Auth.Form.Password')" prop="password">
                            <el-input type="password" v-model="ruleForm.password" autocomplete="off" @keyup.enter.native="submitForm('ruleForm')"></el-input>
                        </el-form-item>
                        <el-button class="submit-btn" type="primary" @click="submitForm('ruleForm')">{{ $t('Auth.Form.BtnSignIn') }}</el-button>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane :label="$t('Auth.Form.Tab2')" name="MobileSMSCaptcha">
                    <el-form label-position="top" :model="ruleForm2" status-icon :rules="rules2" ref="ruleForm2" label-width="100px">
                        <el-form-item :label="$t('Auth.Form.Mobile')" prop="mobile">
                            <el-input type="text" v-model="ruleForm2.mobile">
                                <el-dropdown slot="prepend" @command="handleCommand">
                                    <span class="el-dropdown-link">
                                        <span>{{dropVal[mobileSelect]}}</span>
                                        <i class="el-icon-arrow-down el-icon--right"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item :key="key" v-for="(drop, key) in dropVal" :command="key">{{ drop }}</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </el-input>
                        </el-form-item>
                        <el-form-item class="captcha-item" :label="$t('Auth.Form.Captcha')" prop="captcha">
                            <el-input type="text" v-model="captcha" autocomplete="off">
                                <template slot="prepend">
                                    <img style="position: relative;top: 2px;cursor: pointer" :src="captchaSrc" @click="getCaptcha()" alt="">
                                </template>
                            </el-input>
                        </el-form-item>
                        <el-form-item :label="$t('Auth.Form.SMSCaptcha')" prop="smsAuthCode">
                            <el-input type="text" v-model="ruleForm2.smsAuthCode" autocomplete="off">
                                <template slot="append">
                                    <el-button :disabled=smsBtnDisabled @click="getSmsAuthCode" slot="append">{{ smsBtnContent }}</el-button>
                                </template>
                            </el-input>
                        </el-form-item>
                        <el-button class="submit-btn" type="primary" @click="submitForm('ruleForm2')">{{ $t('Auth.Form.BtnSignIn') }}</el-button>
                    </el-form>
                </el-tab-pane>
            </el-tabs>
            <div class="auth-bot-text">
                <span>{{ $t('Auth.Link.PrToSignUp') }}<router-link class="oxo-link primary" to="/signup">{{ $t('Auth.Link.ToSignUp') }}</router-link></span>
            </div>
        </div>
    </div>
</template>

<script>
    // import Vue from 'vue'

    export default {
        data() {
            var validateUser = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error(' '));
                } else {
                    // if (this.ruleForm.checkPass !== '') {
                    //     this.$refs.ruleForm.validateField('checkPass');
                    // }
                    callback();
                }
            };
            var validatePass = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error(' '));
                } else {
                    callback();
                }
            };
            var validateMobile = (rule, value, callback) => {
                if (value === '' || ! /^1\d{10}$/.test(value)) {
                    callback(new Error(this.$t('Auth.Hint.S2')));
                } else {
                    callback();
                }
            };
            var validateSmsAuthCode = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error(' '));
                } else {
                    callback();
                }
            };
            var dropVal = {
                '86': '+86',
            };
            const smsBtnEnableContent =  this.$t('Auth.Form.BtnX')
            const smsBtnDisabledContent =  this.$t('Auth.Form.BtnXDisabled')
            return {
                dropVal,
                captchaSrc: '',
                captcha: '',
                signInMethod: 'UsernameAndPassword',
                mobileSelect: '86',
                ruleForm: {
                    username: this.$cookie.get('unk') && this.$cookie.get('unk') !== 'undefined'
                        ? this.$cookie.get('unk') : '',
                    password: '',
                },
                ruleForm2: {
                    mobile: '',
                    smsAuthCode: '',
                },
                rules: {
                    username: [
                        { validator: validateUser, trigger: 'blur' }
                    ],
                    password: [
                        { validator: validatePass, trigger: 'blur' }
                    ],
                },
                rules2: {
                    mobile: [
                        { validator: validateMobile, trigger: 'blur' }
                    ],
                    smsAuthCode: [
                        { validator: validateSmsAuthCode, trigger: 'blur' }
                    ],
                },
                totalTime: 60,
                smsBtnContent: smsBtnEnableContent,
                smsBtnEnableContent,
                smsBtnDisabledContent,
                smsBtnDisabled: false,
            };
        },
        created () {
            this.getCaptcha()
        },
        methods: {
            tabClick(tab, event) {
                // console.log(tab);
            },
            countDown() {
                this.smsBtnDisabled = true
                this.smsBtnContent = this.totalTime + this.smsBtnDisabledContent
                let clock = window.setInterval(() => {
                    this.totalTime--
                    this.smsBtnContent = this.totalTime + this.smsBtnDisabledContent
                    if (this.totalTime < 0) {
                        this.getCaptcha()
                        this.smsBtnDisabled = false
                        window.clearInterval(clock)
                        this.smsBtnContent = this.smsBtnEnableContent
                        this.totalTime = 60
                    }
                }, 1000)
            },
            getCaptcha() {
                this.captcha = ''
                this.$axios.get('/captcha')
                    .then(res => {
                        this.captchaSrc = res.imgSrc
                    }, err => {})
            },
            getSmsAuthCode() {
                if (this.ruleForm2.mobile === '' || ! /^1\d{10}$/.test(this.ruleForm2.mobile)) {
                    this.$message.error(this.$t('Auth.Hint.S2'))
                    return false
                } else if (this.captcha === '') {
                    this.$message.error(this.$t('Auth.Hint.S4'))
                    return false
                }

                this.$axios.post('/signInSMSCaptcha', {
                    fullscreenLoading: true,
                    mobile: this.ruleForm2.mobile,
                    captcha: this.captcha,
                }).then(res => {
                    this.$message.success(this.$t('Auth.Hint.S5'))
                    this.countDown()
                }, err => {
                    this.getCaptcha()
                })
            },
            submitForm(formName) {
                // this.$message.error('dasdasdsa')
                this.$refs[formName].validate((valid) => {

                    if (valid) {

                        if (this.signInMethod === 'UsernameAndPassword') {

                            this.$axios.post('/signIn', {
                                fullscreenLoading: true,
                                ...this.ruleForm,
                            }).then(res => {

                                this.$message.success(this.$t('Auth.Hint.SignInSuccess'))

                                localStorage.setItem('userInfo', res.userInfo)

                                this.$cookie.set('unk', this.ruleForm.username, 604800)

                                this.$router.push({path: '/console'})

                            }, err => {})

                        } else {

                            this.$axios.post('/signInWithSMSCaptcha', {
                                fullscreenLoading: true,
                                ...this.ruleForm2,
                            }).then(res => {

                                this.$message.success(this.$t('Auth.Hint.SignInSuccess'))

                                localStorage.setItem('userInfo', res.userInfo)

                                this.$router.push({path: '/console'})

                            }, err => {})
                        }


                    } else {
                        // console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields()
            },
            handleCommand (command) {
                this.ruleForm.mobileSelect = command;
            }
        }
    }
</script>

<style lang="scss">
    .auth-form {
        .el-tabs__header {
            margin-bottom: 36px;
        }
        .el-tabs__nav {
            width: 100%;
        }
        .el-tabs__item {
            width: 50%;
            text-align: center;
            font-size: 16px;
        }
        .el-dropdown-link {
            cursor: pointer;
        }
        .el-input-group__append {
            /*background: none;*/
            width: 150px;
            text-align: center;
            .el-button {
                color: #1389FD
            }
        }
        .el-button.is-disabled {
            color: #bababa;
        }
        .captcha-item {
            .el-input-group__prepend {
                padding: 0 13px;
            }
        }
    }
</style>