<template>
    <div style="width: 100%;overflow: hidden">
        <div class="common-container">
            <div class="section">
                <div class="sub_s s_1_l">
                    <img src="../assets/images/pbai_1.png" style="width: 520px;margin-top: 60px;position: relative;z-index: 10" alt="">
                </div>
                <div class="sub_s s_1_r">
                    <img src="../assets/images/pbai_2.png" style="width: 280px" alt="">
                    <h1 style="margin-top: 160px">照片书一键生成</h1>
                    <h2 style="margin-bottom: 60px">人工智能AI驱动</h2>
                    <p>世界上70%的照片书没有完成排版客户就放弃了。</p>
                    <p>我们用人工智能帮客户自动完成。</p>
                </div>
                <img class="ai-dou" src="../assets/images/pbai_5.png" alt="">
            </div>
        </div>
        <div class="elementor-shape elementor-shape-bottom" style="width: 100%;">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none"> <path class="elementor-shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
    c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
    c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path> </svg>
        </div>
        <div class="ai-full-container">
            <div class="common-container">
                <div class="section">
                    <div class="sub_s s_1_l">
                        <img src="../assets/images/pbai_3.png" style="width: 520px;margin-top: 60px;position: relative;z-index: 10" alt="">
                    </div>
                    <div class="sub_s s_1_r">
                        <h1 style="margin-top: 180px">无需操作生成照片书</h1>
                        <p>我们的SDK通过分析客户的拍照习惯，用旅途、相机使用模式以及周围人物构建"故事地图"。</p>
                        <p>然后自动归集照片成如故事情节般的照片书。</p>
                    </div>
                </div>
                <div class="section">
                    <div class="sub_s s_1_l">
                        <h1 style="margin-top: 130px;text-align: right">会讲故事的照片书</h1>
                        <p>我们的核心引擎自动组合最美好的片段，把每一页，每一个段落变成一段情节，让客户拥有一本值得永久珍藏的记忆。</p>
                        <p></p>
                        <p>没有固定的模版，每个人、每本书都与众不同。</p>
                    </div>
                    <div class="sub_s s_1_r">
                        <img src="../assets/images/pbai_4.png" style="float: right;width: 520px;margin-top: 60px;position: relative;z-index: 10" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PhotobookAi"
    }
</script>

<style scoped>
    .section {
        width: 100%;
        position: relative;
        height: 660px;
        color: #494b4d;
    }
    .ai-full-container {
        overflow: hidden;
        position: relative;
        margin-top: -112px;
        width: 100%;
        background: #f6f6f6;
    }
    .sub_s {
        float: left;
    }
    .s_1_l {
        width: 50%;
    }
    .s_1_r {
        position: relative;
        width: 50%;
    }
    .s_1_r img {
        position: absolute;
        right: 0;
        top: -66px;
    }
    h1 {
        font-size: 2.9rem;
    }
    h2  {
        font-size: 1.8rem;
    }
    p {
        font-size: 1.45rem;
    }
    .elementor-shape {
        z-index: 1;
        position: relative;
        top: -112px;
        overflow: hidden;
        left: 0;
        width: 100%;
        line-height: 0;
        direction: ltr;
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    .elementor-shape-bottom {
        bottom: -1px;
    }
    .elementor-shape svg {
        display: block;
        position: relative;
        left: 50%;
        width: calc(153% + 1.3px);
        height: 112px;
        transform: translateX(-50%) rotateY(180deg);
    }
    .elementor-shape-fill {
        fill: #f6f6f6;
    }
    .ai-dou {
        width: 360px;
        position: absolute;
        top: 240px;
        right: -290px;
        /*margin-right: -190px;*/
        z-index: 50;
    }
</style>