<template>
    <div class="common-container">
        <div class="auth-form auth-form-large">
            <h2 class="form-title">{{ $t('Auth.Title.SignUp') }}</h2>
            <el-form label-position="top" :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-row :gutter="56">
                    <el-col :span="12">
                        <el-form-item :label="$t('Auth.Form.CorpName')" prop="corpName">
                            <el-input v-model="ruleForm.corpName"></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('Auth.Form.Username')" prop="username">
                            <el-input v-model="ruleForm.username" :placeholder="$t('Auth.Form.UserPlaceholder')"></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('Auth.Form.Password')" prop="password">
                            <el-input type="password" v-model="ruleForm.password" :placeholder="$t('Auth.Form.PasswordPlaceholder')" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('Auth.Form.ConfirmPassword')" prop="password_confirmation">
                            <el-input type="password" v-model="ruleForm.password_confirmation" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button class="submit-btn" type="primary" @click="submitForm('ruleForm')">{{ $t('Auth.Form.BtnSignUp') }}</el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-checkbox v-model="agreementChecked">{{ $t('Auth.Link.PrAgreement') }}<a class="oxo-link primary" href="/agreement" target="_blank">{{ $t('Auth.Link.Agreement') }}</a></el-checkbox>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">

                        <el-form-item :label="$t('Auth.Form.Contact')" prop="contact">
                            <el-input v-model="ruleForm.contact"></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('Auth.Form.Mobile')" prop="mobile">
                            <el-input v-model.number="ruleForm.mobile" class="input-with-select">
                                <el-dropdown slot="prepend" @command="handleCommand">
                                    <span class="el-dropdown-link">
                                        <span>{{dropVal[ruleForm.mobileSelect]}}</span>
                                        <i class="el-icon-arrow-down el-icon--right"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item :key="key" v-for="(drop, key) in dropVal" :command="key">{{ drop }}</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </el-input>
                        </el-form-item>
                        <el-form-item :label="$t('Auth.Form.Captcha')" prop="captcha">
                            <el-input type="text" v-model="captcha" autocomplete="off">
                                <template slot="prepend">
                                    <img style="position: relative;top: 2px;cursor: pointer" :src="captchaSrc" @click="getCaptcha()" alt="">
                                </template>
                            </el-input>
                        </el-form-item>
                        <el-form-item :label="$t('Auth.Form.SMSCaptcha')" prop="smsAuthCode">
                            <el-input type="text" v-model="ruleForm.smsAuthCode" autocomplete="off">
                                <template slot="append">
                                    <el-button :disabled=smsBtnDisabled @click="getSmsAuthCode" slot="append">{{ smsBtnContent }}</el-button>
                                </template>
                            </el-input>
                        </el-form-item>
                        <el-form-item class="auth-bot-text">
                            <span>{{ $t('Auth.Link.PrToSignIn') }} <router-link class="oxo-link primary" to="/signin">{{ $t('Auth.Link.ToSignIn') }}</router-link></span>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            var validatePass = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error(' '));
                } else {
                    if (this.ruleForm.password_confirmation !== '') {
                        this.$refs.ruleForm.validateField('password_confirmation');
                    }
                    callback();
                }
            };
            var validatePass_ = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error(' '));
                } else if (value !== this.ruleForm.password) {
                    callback(new Error(this.$t('Auth.Hint.S1')));
                } else {
                    callback();
                }
            };
            var validateCorpName = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error(' '));
                } else {
                    callback();
                }
            };
            var validateContact= (rule, value, callback) => {
                if (value === '') {
                    callback(new Error(' '));
                } else {
                    callback();
                }
            };
            var validateMobile = (rule, value, callback) => {
                if (value === '' || ! /^1\d{10}$/.test(value)) {
                    callback(new Error(this.$t('Auth.Hint.S2')));
                } else {
                    callback();
                }
            };
            var validateUsername = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error(' '));
                } else {
                    callback();
                }
            };
            var validateSmsAuthCode = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error(' '));
                } else {
                    callback();
                }
            };
            var dropVal = {
                '0086': '+86',
            };
            const smsBtnEnableContent =  this.$t('Auth.Form.BtnX')
            const smsBtnDisabledContent =  this.$t('Auth.Form.BtnXDisabled')
            return {
                dropVal,
                captchaSrc: '',
                captcha: '',
                agreementChecked: true,
                ruleForm: {
                    password: '',
                    password_confirmation: '',
                    corpName: '',
                    contact: '',
                    mobile: '',
                    mobileSelect: '0086',
                    username: '',
                    smsAuthCode: '',
                },
                rules: {
                    password: [
                        { validator: validatePass, trigger: 'blur' }
                    ],
                    password_confirmation: [
                        { validator: validatePass_, trigger: 'blur' }
                    ],
                    corpName: [
                        { validator: validateCorpName, trigger: 'blur' }
                    ],
                    contact: [
                        { validator: validateContact, trigger: 'blur' }
                    ],
                    mobile: [
                        { validator: validateMobile, trigger: 'blur' }
                    ],
                    username: [
                        { validator: validateUsername, trigger: 'blur' }
                    ],
                    smsAuthCode: [
                        { validator: validateSmsAuthCode, trigger: 'blur' }
                    ],
                },
                totalTime: 60,
                smsBtnContent: smsBtnEnableContent,
                smsBtnEnableContent,
                smsBtnDisabledContent,
                smsBtnDisabled: false,
            };
        },
        created () {
            this.getCaptcha()
        },
        methods: {
            countDown() {
                this.smsBtnDisabled = true
                this.smsBtnContent = this.totalTime + this.smsBtnDisabledContent
                let clock = window.setInterval(() => {
                    this.totalTime--
                    this.smsBtnContent = this.totalTime + this.smsBtnDisabledContent
                    if (this.totalTime < 0) {
                        this.getCaptcha()
                        this.smsBtnDisabled = false
                        window.clearInterval(clock)
                        this.smsBtnContent = this.smsBtnEnableContent
                        this.totalTime = 60
                    }
                }, 1000)
            },
            getCaptcha() {
                this.captcha = ''
                this.$axios.get('/captcha')
                    .then(res => {
                        this.captchaSrc = res.imgSrc
                    }, err => {})
            },
            getSmsAuthCode() {
                if (this.ruleForm.mobile === '' || ! /^1\d{10}$/.test(this.ruleForm.mobile)) {
                    this.$message.error(this.$t('Auth.Hint.S2'))
                    return false
                } else if (this.captcha === '') {
                    this.$message.error(this.$t('Auth.Hint.S4'))
                    return false
                }

                this.$axios.post('/signUpSMSCaptcha', {
                    fullscreenLoading: true,
                    mobile: this.ruleForm.mobile,
                    captcha: this.captcha,
                }).then(res => {
                    this.$message.success(this.$t('Auth.Hint.S5'))
                    this.countDown()
                }, err => {
                    this.getCaptcha()
                })
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (! this.agreementChecked) {
                            this.$message({
                                message: this.$t('Auth.Hint.S3'),
                                type: 'warning'
                            });
                            return false
                        }

                        this.$axios.post('/signUp', {
                            fullscreenLoading: true,
                            ...this.ruleForm,
                            // username: this.ruleForm.username,
                            // password: this.ruleForm.password,
                            // password_confirmation: this.ruleForm.password_confirmation,
                            // corpname: this.ruleForm.corpname,
                            // contact: this.ruleForm.contact,
                            // mobile: this.ruleForm.mobile,
                            // smsAuthCode: this.ruleForm.smsAuthCode,
                        }).then(res => {
                            // console.log(res)
                            // window.localStorage.setItem('isLogin', 'Yes')
                            // window.localStorage.setItem('corpname', res.corpname)
                            // window.localStorage.setItem('username', res.username)
                            // window.localStorage.setItem('username', this.ruleForm.username)
                            // window.localStorage.setItem('xusername', res.username)
                            // window.location.href = '/console'
                            // this.$router.push({path: '/'})
                            this.$message.success(this.$t('Auth.Hint.SignUpSuccess'))
                            localStorage.setItem('userInfo', res.userInfo)
                            this.$router.push({path: '/console'})
                        }, err => {})

                    } else {
                        // console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            handleCommand (command) {
                this.ruleForm.mobileSelect = command;
            }
        }
    }
</script>

<style lang="scss">

    .auth-form {
        .el-select {
            width: 80px;
        }
        .el-checkbox__input.is-checked+.el-checkbox__label {
            color: #565656;
        }
        .el-dropdown-link {
            cursor: pointer;
        }
        .el-input-group__append {
            /*background: none;*/
            width: 150px;
            text-align: center;
            .el-button {
                color: #1389FD
            }
        }
        .el-button.is-disabled {
            color: #bababa;
        }
    }

</style>